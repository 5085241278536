import E from "wangeditor"
export default {
  data () {
    return {
      editor:null,//
      gradeList:[
        {
          value: "A",
          label: "A",
        },
        {
          value: "B",
          label: "B",
        },
        {
          value: "C",
          label: "C",
        },
        {
          value: "D",
          label: "D",
        },
        {
          value: "展位",
          label: "展位",
        },
      ],
      zuGradeList:[
        {
          value: "A",
          label: "A",
        },
        {
          value: "B",
          label: "B",
        },
        {
          value: "C",
          label: "C",
        },
        {
          value: "D",
          label: "D",
        }
      ],
      fangStatus:[
        {
          value: 1,
          label: "未交易"
        },
        {
          value: 3,
          label: "有效暂缓"
        },
        {
          value: 5,
          label: "无效暂缓"
        },
        {
          value: 7,
          label: "我司成交"
        },
        {
          value: 9,
          label: "他司成交"
        }
      ],//房源状态
      openStatus:[
        {
          value: "未开盘",
          label: "未开盘",
        },
        {
          value: "已开盘",
          label: "已开盘",
        }
      ],//开盘状态
      plotList:[],//小区列表
      metroList:[],//地铁列表
      subwayList:[],//地铁站列表
      loucengTypeList:[
        {
          value: "地下室",
          label: "地下室",
        },
        {
          value: "低",
          label: "低",
        },
        {
          value: "中",
          label: "中",
        },
        {
          value: "高",
          label: "高",
        },
        {
          value: "顶",
          label: "顶",
        }
      ],//楼层区域
      
      fitmentList:[
        {
          value: "毛坯",
          label: "毛坯",
        },
        {
          value: "简单装修",
          label: "简单装修",
        },
        {
          value: "居家装修",
          label: "居家装修",
        },
        {
          value: "精装修",
          label: "精装修",
        },
        {
          value: "豪华装修",
          label: "豪华装修",
        }
      ],//装修
      panTime:[
        {
          value: "首开",
          label: "首开",
        },
        {
          value: "加推",
          label: "加推",
        }
      ],//开盘次数
      sourceList:[],//信息来源
      menDianList:[],//门店列表
      netList:[
        {
          value: "内网",
          label: "仅内网展示",
        },
        {
          value: "外网",
          label: "内网小程序均展示",
        }
      ],//内外网房源
      certificateList:[
        {
          value: "身份证",
          label: "身份证",
        },
        {
          value: "房产证",
          label: "房产证",
        }
      ],//证件列表
      houseTagList:[],//房屋标签
      houseSelectList:[],//户型选择
      houseSelectIndex:0,//选择那个
      payList:[
        {
          value: "月付",
          label: "月付",
        },
        {
          value: "季付",
          label: "季付",
        },
        {
          value: "半年付",
          label: "半年付",
        },
        {
          value: "年付",
          label: "年付",
        }
      ],//付款方式
      certificateTypeList:[
        {
          value: "证件1",
          label: "证件1",
        },
        {
          value: "证件2",
          label: "证件2",
        },
        {
          value: "证件3",
          label: "证件3",
        }
      ],//证书类型
      
      
      purchaseTimeList:[
        {
          value: '不满两年',
          label: "不满两年",
        },
        {
          value: '满两年',
          label: "满两年",
        },
        {
          value: '满五年唯一',
          label: "满五年唯一",
        },
        {
          value: '满五年不唯一',
          label: "满五年不唯一",
        }
      ],//购入时间
      actualityList:[
        {
          value: '空关',
          label: "空关",
        },
        {
          value: '有租期',
          label: "有租期",
        },
        {
          value: '租期多久到期',
          label: "租期多久到期",
        }
      ],//房屋现状
      pledgeList:[
        {
          value: 1,
          label: "有",
        },
        {
          value: 2,
          label: "无",
        }
      ],//有无抵押
      repayList:[
        {
          value: '自还',
          label: '自还',
        },
        {
          value: '下家还',
          label: "下家还",
        }
      ],//偿还方式
      openingList:[
        {
          value: 1,
          label: '1年'
        },
        {
          value: 2,
          label: '2年'
        },
        {
          value: 3,
          label: '3年'
        },
        {
          value: 4,
          label: '4年'
        },
        {
          value: 5,
          label: '5年'
        },
        {
          value: 6,
          label: '6年'
        },
        {
          value: 7,
          label: '7年'
        },
        {
          value: 8,
          label: '8年'
        },
        {
          value: 9,
          label: '9年'
        },
        {
          value: 10,
          label: '10年'
        },
        {
          value: 13,
          label: '13年'
        },
        {
          value: 15,
          label: '15年'
        },
        {
          value: 17,
          label: '17年'
        },
        {
          value: 19,
          label: '19年'
        },
        {
          value: 20,
          label: '20年'
        }
      ],
      
      rules: {
        fang_level: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        region: [
          { required: true, message: '请选择活动区域', trigger: 'change' }
        ],
        date1: [
          { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
        ],
        date2: [
          { type: 'date', required: true, message: '请选择时间', trigger: 'change' }
        ],
        type: [
          { type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }
        ],
        resource: [
          { required: true, message: '请选择活动资源', trigger: 'change' }
        ],
        desc: [
          { required: true, message: '请填写活动形式', trigger: 'blur' }
        ]
      }
    }
  },
  methods:{
    //视频
    // eslint-disable-next-line no-unused-vars
    handleRemoveVideoImg (file, fileList) {
      let data = []
      fileList.forEach(em=>{
        data.push(em.response.data.fullurl)
      })
      this.fang_vediofile = data
    },
    //删除上传1视频
    cancelUpload (fang_vediofile,index) {
      this[fang_vediofile].splice(index,1)
    },
    //视频
    uploadVideoImg (response,name) {
      const loading = this.$loading({
        lock: true,
        text: '文件上传中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.$axios.uploadFile(response.target.files[0]).then(res=>{
        loading.close();
        this[name].push(res.fullurl)
      })
      // this.fang_vediofile.push(response.data.fullurl)
    },
    //删除轮播图
    // eslint-disable-next-line no-unused-vars
    handleRemoveBanner (file, fileList) {
      let data = []
      fileList.forEach(em=>{
        data.push(em.response.data.fullurl)
      })
      this.fang_lunbo_images = data
    },
    //轮播图
    uploadBannerImg (response) {
      this.fang_lunbo_images.push(response.data.fullurl)
    },
    //删除封面图
    // eslint-disable-next-line no-unused-vars
    handleRemoveCover (file, fileList) {
      let data = []
      fileList.forEach(em=>{
        data.push(em.response.data.fullurl)
      })
      this.fang_fengmian_image = data
    },
    //封面图
    uploadCoverImg (response) {
      this.from.fang_fengmian_image = response.data.fullurl
    },
    //小区交房时间
    changeDeal (e) {
      this.from.fang_jiao = e * 1 / 1000
    },
    //装修时间
    changeFitment (e) {
      this.from.fang_xiu_time = e * 1 / 1000
    },
    //选择标签
    changeTag (item) {
      item.checked = !item.checked
      if (this.houseTagList.some((em) => em.checked)) {
        let tag = [];
        this.houseTagList.forEach((em) => {
          if (em.checked) {
            tag.push(em.name);
          }
        });
        this.from.fang_biaoqian = tag.join(",");
      } else {
        this.from.fang_biaoqian = ''
      }
    },
    //上传委托书
    uploadEntrustImg (response) {
      this.from.weituo_images = response.data.fullurl
    },
    //上传身份证
    uploadIdentityImg (response) {
      this.from.identity_images = response.data.fullurl
    },
    //删除房产证照片
    handleRemoveHousing (file, fileList) {
      let data = []
      fileList.forEach(em=>{
        data.push(em.response.data.fullurl)
      })
      this.fang_chancheng_images = data
    },
    //上传房产证
    uploadHousingImg (response) {
      this.from.fang_chancheng_images = response.data.fullurl
    },
    //选择地铁
    changeMetro (e) {
      if (e.length) {
        let ditie = []
        e.forEach(item=>{
          this.metroList.forEach(em=>{
            if (item == em.id) {
              ditie.push(em.name)
            }
          })
        })
        this.from.ditie = ditie.join(",");
        if (this.from.ditie) {
          this.$axios.diTieZhan({
            id:e.join(',')
          }).then(res=>{
            this.subwayList = res.data
          })
        }
        
      }
    },
    //选择地铁站
    changeDitieAhan (e) {
      if (e.length) {
        let ditie = []
        e.forEach(item=>{
          this.subwayList.forEach(em=>{
            if (item == em.id) {
              ditie.push(em.name)
            }
          })
        })
        this.from.ditie_zhan = ditie.join(",");
      }
    },
    //获取经纬度
    getLocation () {
      if (this.from.fang_address && this.from.fang_city) {
        this.$axios.address({address:this.from.fang_city.split('/').join('') + this.from.fang_address}).then(res=>{
          let location = res.data.result.location
          this.from.fang_jingwei =  location.lng + ',' + location.lat
        })
      }
    },
    //获取商圈
    getShangQuanList () {
      this.$axios.shangQuan({
        city:this.from.fang_city
      }).then(res=>{
        this.shangquanList = res.data
      })
    },
    //选择小区
    changePlot (e) {
      let data = this.plotList.filter(item=>item.id == e)[0]
      if (data) {
        this.$axios.xiaoQuDetail({
          id:data.id
        }).then(res=>{
          this.houseSelectList = res.data.huxing_id
        })
        this.$axios.xiaoQuTi({
          xiaoqu_id:data.id
        }).then(res=>{
          this.tiHuList = res.data
        })
      }
      
    },
    getXiaoQuList (id) {
      this.$axios.xiaoQuList({
        page:1,
        pageNum:100000
      }).then(res=>{
        this.plotList = this.getFTPrivilegeXiaoQuList(res.data.data)
        id ? this.changePlot(id) : ''
      })
      this.$axios.fangSpans({
        type:2
      }).then(res=>{
        let data = []
        res.data.forEach(em=>{
          data.push({
            name:em,
            checked:false
          })
        })
        this.$axios.menDian().then(res=>{
          this.menDianList = res.data
        })
        if (this.details && this.details.fang_biaoqian && this.details.fang_biaoqian.length) {
          for (let index = 0; index < this.details.fang_biaoqian.length; index++) {
            for (let j = 0; j < data.length; j++) {
              if (this.details.fang_biaoqian[index] ==  data[j].name) {
                data[j].checked = true
              }
            }
          }
        }
        
        this.houseTagList = data
      })
    }
  },
  mounted () {
    this.editor = new E("#rich")
    this.editor.config.height = 450
    this.editor.config.customUploadImg =  (resultFiles, insertImgFn) => {
      // resultFiles 是 input 中选中的文件列表
      // insertImgFn 是获取图片 url 后，插入到编辑器的方法
  
      // 上传图片，返回结果，将图片插入到编辑器中
      let param = new FormData(); // 创建form对象
      param.append("file", resultFiles[0]); // 通过append向form对象添加数据
      this.$axios.upload(param).then(res=>{
        insertImgFn(res.data.fullurl)
      })
  }
    this.editor.create()
  },
  created() {
    this.getXiaoQuList()
  }
}